//Core
import React, {lazy, Suspense} from "react";
import {Routes, Route, Navigate, useNavigate, useLocation} from "react-router-dom";
// import {useAuth0} from "@auth0/auth0-react";
//Styles
// import {Typography} from "@mui/material";
import {MainBody} from "./Main.styles";
// import {GreenButton} from "../../../styled/buttons";
//Hooks
import {useMain} from "./useMain";
//Components
import Sidebar from "../sidebar/Sidebar";
import Header from "../header/Header";
import Loading from "../loading/Loading";
import {Snack} from "../snack/Snack";
import Login from "../../mainContent/login/Login";
//Utils
import {pathWithUTM} from "../../../utils/pathWithUTM";

// Pages
const Dashboard = lazy(() => import("../../mainContent/dashboard/Dashboard"));
const PPC = lazy(() => import("../../mainContent/ppc/PPC"));
const PR = lazy(() => import("../../mainContent/pr/PR"));
const DataMining = lazy(() => import("../../mainContent/dataMining/DataMining"));
const GeneralSettings = lazy(() => import("../../mainContent/generalSettings/GeneralSettings"));
const Support = lazy(() => import("../../mainContent/support/Support"));
const BillingCenterPage = lazy(() => import("../../mainContent/billingCenter/BillingCenterPage"));
const Page404 = lazy(() => import("../../mainContent/page404/Page404"));

const Main = () => {
    const {noUser, sideBarOpen, setSideBarOpen, loading} = useMain();
    // const {loginWithPopup} = useAuth0();
    const navigate = useNavigate();
    const location = useLocation();

    const isLoginPage = location.pathname === '/login';
    console.log(location.pathname)

    if (!loading && noUser && !isLoginPage) navigate(pathWithUTM("/login"));
    // if (noUser) {
    //     return (
    //         <Routes>
    //              <Route path={'login'} element={<Login/>}/>
    //          </Routes>
    //       <MainBody className={"login"}>
    //           <Typography variant="h2" pt={{xs: "10rem", mobL: "5rem"}} textAlign={"center"}>
    //               Please log in in order to continue!
    //           </Typography>
    //
    //   <GreenButton onClick={loginWithPopup}>Log in</GreenButton>
    // </MainBody>
    // );
    // }

    if (loading) return <Loading/>;

     else return (
        <>
            {!noUser && !isLoginPage && <Header offset={sideBarOpen ? "37rem" : "12rem"}/>}
            {!noUser && !isLoginPage && <Sidebar open={sideBarOpen} setOpen={setSideBarOpen}/>}
            <Suspense fallback={<Loading/>}>
                <MainBody sx={{ml: {mobL: isLoginPage ? 0 : sideBarOpen ? "35rem" : "10rem"}}} className={isLoginPage ? 'login' : ''}>
                    <Routes>
                        <Route path="/" element={<Navigate to={pathWithUTM(noUser ? "/login" : "/dashboard")}/>}/>
                        <Route path='login' element={<Login/>}/>
                        <Route path="dashboard" element={<Dashboard/>}/>
                        <Route path="ppc/*" element={<PPC/>}/>
                        <Route path="pr/*" element={<PR/>}/>
                        <Route path="data_mining/*" element={<DataMining/>}/>
                        <Route path="settings" element={<GeneralSettings/>}/>
                        <Route path="support" element={<Support/>}/>
                        <Route path="billing/*" element={<BillingCenterPage/>}/>
                        <Route path="404" element={<Page404/>}/>
                        <Route path="*" element={<Navigate to={pathWithUTM("/404")}/>}/>
                    </Routes>
                    <Snack/>
                </MainBody>
            </Suspense>
        </>
    );

};
export default Main;
